import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";

const HeroImage = () => {
  return (
    <h1>
      <div class="relative md:block text-white">
        <StaticImage src="../images/information/information_top.png" />

        <div
          class="absolute top-0 text-left"
          style={{ marginTop: "8.5%", marginLeft: "10%" }}
        >
          <div class="text-sm md:text-2xl lg:text-3xl font-bold">
            ごあいさつ
          </div>
          <div class="text-xs md:text-base lg:text-lg font-semibold">
            INFORMATION
          </div>
        </div>
      </div>
    </h1>
  );
};

const TacText = (props) => {
  return (
    <div class="text-2xs sm:text-sm md:text-base lg:text-lg whitespace-nowrap font-normal">
      {props.children}
    </div>
  );
};

const InformationPage = () => {
  return (
    <Layout>
      <div class="container mx-auto">
        <div class="mx-auto text-center">
          <HeroImage />

          <h2 class="py-8">
            <div class="text-primary text-2xl font-semibold">ごあいさつ</div>
            <div class="text-secondary text-lg">INFORMATION</div>
          </h2>

          <div class="w-full">
            <div class="w-10/12 mx-auto text-left md:text-lg md:px-2">
              <p>
                製造業における技術進歩はめざましく、また生産形態は従来の大量生産から多品種少量生産へシフトするなど、ものづくりの多様化が進んでおります。
              </p>
              <p>
                生き残るために製造業には大きな変革が求められており、さらに労働力人口の減少がもたらす影響「人手不足」に直面、市場競争の激化により「生産性の向上」「作業効率の向上」を余儀なくされています。
              </p>
              <br />
              <p>
                そのような大きな変革が求められる中、私たち株式会社TACはお客様の課題に真摯に向き合うと同時に、弊社創業以来培ってきた独創的かつ創造力のある思考とソフトウェアを加えた総合的な技術力を最大限に活用し、生産性向上のための自動装置の提案、設計・製造・据付稼働まで一貫して対応してまいりました。
              </p>
              <p>
                このことはお客様の生産性・作業効率の向上、人手不足を解消し、利益拡大に大きく貢献できるものと自負しております。
              </p>
              <br />
              <p>
                これからもＴＡＣは「質」の追求と「スピード」「フレキシビリティ」を第一に、一丸となってお客様に満足をご提供することを目指してまいります。
              </p>
              <p>今後もより一層のご愛顧を心よりお願い申し上げます。</p>
              <br />
              <div class="max-w-min ml-auto mr-0 whitespace-nowrap">
                <div>株式会社 TAC</div>
                <div>代表取締役社長 原田正敏</div>
              </div>
            </div>
          </div>

          {/* 社名に込めた思い */}
          <div class="mt-10">
            <div class="py-20">
              <h2 class="md:text-3xl md:font-bold py-2">社名に込めた思い</h2>
              <StaticImage
                src="../images/information/underline.png"
                class="mx-auto"
              />
            </div>

            <div class="relative w-full">
              <StaticImage src="../images/information/world.png" />

              {/* Thinking  */}
              <div
                class="absolute top-0 w-full text-left h-1/6 flex flex-col justify-center"
                style={{ marginTop: "2%" }}
              >
                <div
                  class="flex flex-row items-baseline"
                  style={{ marginLeft: "9.5%" }}
                >
                  <span class="text-xl md:text-7xl lg:text-8xl text-tac_tc font-black">
                    T
                  </span>
                  <span class="text-lg md:text-4xl lg:text-5xl text-tac_small font-black">
                    hinking
                  </span>
                  <span
                    class="mr-auto overflow-hidden"
                    style={{ marginLeft: "13%" }}
                  >
                    <TacText>造ることの思考集団を目指しています</TacText>
                  </span>
                </div>
              </div>

              {/* Advantage  */}
              <div
                class="absolute top-0 w-full text-left h-1/6 flex flex-col justify-center"
                style={{ marginTop: "24%" }}
              >
                <div
                  class="flex flex-row items-baseline"
                  style={{ marginLeft: "10%" }}
                >
                  <span class="text-xl md:text-7xl lg:text-8xl text-tac_a font-black">
                    A
                  </span>
                  <span class="text-lg md:text-4xl lg:text-5xl text-tac_small font-black">
                    dvantage
                  </span>

                  <div
                    class="mr-auto overflow-hidden"
                    style={{ marginLeft: "13%" }}
                  >
                    <TacText>経験と技術で製造工程設備を考えます</TacText>
                  </div>
                </div>
              </div>

              <div
                class="absolute top-0 w-full text-left h-1/6 flex flex-col justify-center"
                style={{ marginTop: "44%" }}
              >
                <div
                  class="flex flex-row flex-nowrap items-end"
                  style={{ marginLeft: "10.5%" }}
                >
                  <div class="text-xl md:text-7xl lg:text-8xl text-tac_tc font-black">
                    C
                  </div>
                  <div class="text-base md:text-4xl lg:text-5xl text-tac_small font-black tracking-tighter">
                    ommunication
                  </div>

                  <div
                    class="mr-auto overflow-hidden"
                    style={{ marginLeft: "6%" }}
                  >
                    <TacText>
                      コミュニケーションを通じて、
                      <br />
                      お客様のニーズにフレキシブルにお応えします
                    </TacText>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default InformationPage;
